import React, { useEffect } from "react"
import SharedWishlist from "../../components/account/pages/shared-wishlist"

import { useTranslationContext } from "../../context/TranslationContext"
import useHasMounted from "../../hooks/useHasMounted"

const SharedWishlistPage = () => {
  const { setTranslationContext } = useTranslationContext()
  const hasMounted = useHasMounted()

  useEffect(() => {
    if (hasMounted) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())

      const canonicals = {
        ["en-US"]: `/shared-wishlist/?jwt=${params.jwt}`,
        ["de-DE"]: `/de/shared-wishlist/?jwt=${params.jwt}`,
      }

      setTranslationContext({
        locale: "de-DE",
        canonicals: canonicals,
      })
    }
  }, [hasMounted, setTranslationContext])

  return <SharedWishlist />
}

export default SharedWishlistPage
